import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import Partners from '../components/sections/partners'
import ClickWall from '../components/sections/click-wall'

const isMobile = () => {
  if (typeof window !== 'undefined') {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      window.innerWidth <= 500
    )
  }

  return false
}

const ThankYou = ({ location }) => {
  const overrideOfferId = isMobile() ? 668040 : 668039
  return (
    <Layout phone="866-740-2901" location={location}>
      <SEO
        title="Home"
        keywords={[
          `medicare`,
          `health`,
          `insurance`,
          `plans`,
          `affordable`,
          `supplement`,
        ]}
      />
      <Header phone="866-740-2901" hideCta showGen />
      <Intro
        className="title-content-bg"
        colType="full-col full-col-pt"
        title="We've Also Matched You With These Top Insurance Companies"
        subTitle="Click at least 2 to 3 companies below to find the best rate!"
      />
      <main id="main">
        <ClickWall overrideOfferId={overrideOfferId} />
        <Partners />
      </main>
    </Layout>
  )
}
export default ThankYou
